<i18n lang="json">
{
  "ru": {
    "freeCancellation": "Бесплатная отмена",
    "before": "до",
    "pleaseEnterDates": "Укажите даты, чтобы узнать подробнее о правилах отмены.",
    "freeCancellationUntil": "Бесплатная отмена до",
    "bookingConfirmed": "Бронирование подтверждено",
    "fullRefundPrepayment": "Полный возврат предоплаты",
    "inCaseCancellation": "В случае отмены бронирования — предоплата не возвращается",
    "arrivalDay": "день заезда",
    "more":"Подробнее",
    "note":"При отмене не позднее 5 дней до заезда вы получите полный возврат предоплаты."
  },
  "en": {
    "freeCancellation": "Free Cancellation",
    "before": "before",
    "pleaseEnterDates": "Please enter dates to learn more about the cancellation policy.",
    "freeCancellationUntil": "Free cancellation until",
    "bookingConfirmed": "Booking confirmed",
    "fullRefundPrepayment": "Full refund of prepayment",
    "inCaseCancellation": "In case of cancellation of booking - prepayment is not refundable",
    "arrivalDay": "arrival day",
    "more":"More details",
    "note":"If canceled at least 5 days prior to arrival, you will receive a full refund of the prepayment."
  }
}
</i18n>

<template>
  <div class="free-cancellation" :class="view">
    <template v-if="viewPort === 'mobile'">
      <div class="free-cancellation__body" v-if="freeCancellationDay">
        <strong>{{ t("freeCancellation") }}</strong> {{ t("before") }} {{ freeCancellationDay }}
      </div>
    </template>
    <template v-else>
      <template v-if="!isOccupied">
        {{ t("pleaseEnterDates") }}
      </template>
      <template v-else>
        <template v-if="view === 'detail'">
          <div class="free-cancellation__title">
            <span><span class="zero"></span>₽</span>
            {{ t("freeCancellationUntil") }} {{ freeCancellationDayCrop }}
          </div>
          <div class="free-cancellation__note">
            {{ t("note") }}
          </div>
          <div class="free-cancellation__more">
            <span>
              <span class="more">{{ t("more") }}</span>
              <div class="free-cancellation__modal">
                <div class="title">
                  {{ t("freeCancellationUntil") }} {{ freeCancellationDay }}
                </div>
                <div class="line"><span></span></div>
                <div class="note-row">
                  <div class="note-col">
                    <div class="note-col__title">
                      {{ t("bookingConfirmed") }}
                    </div>
                    <div class="note-col__desk">
                      {{ t("fullRefundPrepayment") }}
                    </div>
                  </div>
                  <div class="note-col">
                    <div class="note-col__title">
                      {{ freeCancellationDay }}
                    </div>
                    <div class="note-col__desk">
                      {{ t("inCaseCancellation") }}
                    </div>
                  </div>
                  <div class="note-col">
                    <div class="note-col__title">
                      {{ arrivalDay }} — {{ t("arrivalDay") }}
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </template>
        <template v-else>
          <span>
            <span>{{ t("freeCancellation") }}</span>
            <div class="free-cancellation__modal">
              <div class="title">
                {{ t("freeCancellationUntil") }} {{ freeCancellationDay }}
              </div>
              <div class="line"><span></span></div>
              <div class="note-row">
                <div class="note-col">
                  <div class="note-col__title">
                    {{ t("bookingConfirmed") }}
                  </div>
                  <div class="note-col__desk">
                    {{ t("fullRefundPrepayment") }}
                  </div>
                </div>
                <div class="note-col">
                  <div class="note-col__title">
                    {{ freeCancellationDay }}
                  </div>
                  <div class="note-col__desk">
                    {{ t("inCaseCancellation") }}
                  </div>
                </div>
                <div class="note-col">
                  <div class="note-col__title">
                    {{ arrivalDay }} — {{ t("arrivalDay") }}
                  </div>
                </div>
              </div>
            </div>
          </span>
          {{ t("before") }} {{ freeCancellationDay }}
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "FreeCancellation",
  props: {
    arrivalDay: {
      type: String,
      default: ''
    },
    freeCancellationDay: {
      type: String,
      default: ''
    },
    freeCancellationDayCrop: {
      type: String,
      default: ''
    },
    isOccupied: {
      type: String,
      default: ''
    },
    view: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapState("user", ["viewPort"])
  }
};
</script>

<style lang="scss" scoped>
.free-cancellation {
  background: #fff;
  color: #000000;
  font-size: 13px;
  padding: 20px;
  margin-top: 20px;
  position: relative;
  .mobile & {
    margin-top: 0;
    padding: 0 20px;
  }
  &.detail {
    padding: 11px 10px 9px;
    border-radius: 3px;
    background-color: #f6f6f6;
    color: #7f7f7f;
    .mobile & {
      background-color: #fff;
      padding: 11px 0 0;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    color: #417505;
    font-weight: bold;
    margin-bottom: 10px;
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50px;
      margin: 0 10px 0 0;
      border: 1px solid #417505;
      color: #417505;
      line-height: 1;
      font-size: 14px;
      font-weight: normal;
      .zero {
        display: inline-block;
        vertical-align: top;
        width: 6px;
        height: 10px;
        border-radius: 3px;
        border: solid 1.2px #417505;
        margin-right: 2px;
      }
    }
  }
  &__note {
    margin-bottom: 8px;
  }
  &__more {
    > span {
      color: #2d6cb4;
      position: relative;
      .more {
        cursor: pointer;
      }
      &:hover > div {
        display: block;
      }
    }
  }
  > span {
    color: #2d6cb4;
    position: relative;
    > span {
      cursor: pointer;
    }
    &:hover > div {
      display: block;
    }
  }
  &__body {
    background-color: #f6f6f6;
    padding: 20px;
  }
  &__modal {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    margin: 4px 0 0 0;
    width: 416px;
    border-radius: 2px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
    color: #444444;
    font-size: 13px;
    padding: 15px 15px 20px;
    z-index: 1;
    .title {
      font-weight: bold;
      margin-bottom: 26px;
    }
    .line {
      height: 3px;
      width: 294px;
      background: linear-gradient(90deg, #6fa759 50%, #e10600 50%);
      position: relative;
      margin-bottom: 20px;
      &:after, &:before, span {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 20px;
        background-color: #feffff;
        position: absolute;
        top: -6px;
      }
      span {
        border: solid 3px #e10600;
        left: 50%;
        margin-left: -6px;
      }
      &:after {
        right: 0;
        border: solid 3px #444444;
      }
      &:before {
        left: 0;
        border: solid 3px #6fa759;
      }
    }
    .note-row {
      display: flex;
      justify-content: space-between;
      .note-col {
        max-width: 106px;
        &__title {
          font-weight: bold;
          margin-bottom: 14px;
        }
      }
    }
  }
}
</style>
